/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import { getUserName } from '../../utils/token';
import { updateBankDetail } from './services';
import useAlert from '../../hooks/useAlert';

const BankDetails = () => {
  const { showAlert } = useAlert();
  const [bankDetail, setBankDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const userName = getUserName();
  const userBankDetails = JSON.parse(localStorage.getItem("user_bank_details"));

  useEffect(() => {
    setBankDetail({
      account_holder_name: userBankDetails?.account_holder_name ? userBankDetails.account_holder_name : "",
      account_number: userBankDetails?.account_number ? userBankDetails.account_number : "",
      bank_name: userBankDetails?.bank_name ? userBankDetails.bank_name : "",
      swift_code: userBankDetails?.swift_code ? userBankDetails.swift_code : "",
    })
  }, []);

  const initialValues = {
    account_holder_name: bankDetail?.account_holder_name ? bankDetail.account_holder_name : "",
    account_number: bankDetail?.account_number ? bankDetail.account_number : "",
    bank_name: bankDetail?.bank_name ? bankDetail.bank_name : "",
    swift_code: bankDetail?.swift_code ? bankDetail.swift_code : "",
  };

  const formSchema = Yup.object().shape({
    account_holder_name: Yup.string()
      .trim()
      .required("Account holder name cannot not be empty")
      .test('text', 'Account holder name should be same as user name', (value) => value === userName),
    account_number: Yup.string()
      .trim()
      .required("Account number cannot not be empty"),
    bank_name: Yup.string()
      .trim()
      .required("Bank name cannot not be empty"),
    swift_code: Yup.string()
      .trim()
      .required("Swift code cannot not be empty"),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      let multi_tsp = localStorage.getItem("multi_tsp");
      multi_tsp = JSON.parse(multi_tsp);

      const bankDetails = {
        ...values,
        access_token: multi_tsp?.wallet?.access_token,
        user_id: multi_tsp?.wallet?.user_id,
      }
      const res = await updateBankDetail(bankDetails);
      if (res?.status) {
        localStorage.setItem("user_bank_details", JSON.stringify(values));
        showAlert("SUCCESS", "Success", res.message);
      } else {
        showAlert("ERROR", "Error", res.message);
      }
      setIsLoading(false);
    },
  });

  return (
    <div className="wallet-form py-4 px-4" >
      <div className="kyc-form-head my-4  row">
        <div className="col">
          {" "}
          <h2 className="kyc-pi ">Confirm your Bank Details</h2>
        </div>
      </div>
      <form className="row g-3 mb-4">
        <div className="col-md-12 my-3">
          <label htmlFor="inputEmail4" className="form-label label-gr">
            Account Holder Name
          </label>
          <input
            type="text"
            className="form-control bg-gr"
            id="inputEmail4"
            placeholder="Account Holder Name"
            name="account_holder_name"
            {...formik.getFieldProps("account_holder_name")}
          />
          {(formik.touched.account_holder_name && formik.errors.account_holder_name) && (
            <p className="text-danger">
              <small>{formik.errors.account_holder_name}</small>
            </p>
          )}
        </div>
        <div className="col-md-12 my-3">
          <label htmlFor="inputEmail4" className="form-label label-gr">
            Account Number
          </label>
          <input
            type="number"
            className="form-control bg-gr"
            id="inputEmail4"
            placeholder="Account Number"
            name="account_number"
            onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
            {...formik.getFieldProps("account_number")}
          />
          {(formik.touched.account_number && formik.errors.account_number) && (
            <p className="text-danger">
              <small>{formik.errors.account_number}</small>
            </p>
          )}
        </div>

        <div className="col-md-12 my-3">
          <label htmlFor="inputEmail4" className="form-label label-gr">
            Bank Name
          </label>
          <input
            type="text"
            className="form-control bg-gr"
            id="inputEmail4"
            placeholder="Bank Name"
            name="bank_name"
            {...formik.getFieldProps("bank_name")}
          />
          {(formik.touched.bank_name && formik.errors.bank_name) && (
            <p className="text-danger">
              <small>{formik.errors.bank_name}</small>
            </p>
          )}
        </div>

        <div className="col-md-12 my-3">
          <label htmlFor="inputEmail4" className="form-label label-gr">
            Swift Code
          </label>
          <input
            type="number"
            className="form-control bg-gr"
            id="inputEmail4"
            placeholder="Swift Code"
            name="swift_code"
            onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
            {...formik.getFieldProps("swift_code")}
          />
          {(formik.touched.swift_code && formik.errors.swift_code) && (
            <p className="text-danger">
              <small>{formik.errors.swift_code}</small>
            </p>
          )}
        </div>
        <div className="col-md-12 my-3 d-grid">
          {
            isLoading ?
              <button type="submit" className="btn mt-4 f-bold kyc-sb no-bdr" disabled>
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </button>
              :
              <button type='submit' onClick={formik.handleSubmit} className="btn btn-primary mt-4 f-bold kyc-sb no-bdr">
                Update
              </button>
          }
        </div>
      </form>
    </div >
  )
}

export default BankDetails