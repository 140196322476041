/* eslint-disable new-cap */
import "../../assets/css/question-answer-card.css";
import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

function QuestionAnswerCard({ question, answer }) {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="question-answer-card-main mb-5 text-start">

            {
                isOpen ? <div className="card border-radidus-16">
                    <div className="card-body row">
                        <div className="col-11">
                            <h5 className="card-title font-18 font-w-600 pt-2 font-272E35" onClick={handleToggle}>{ReactHtmlParser(question)}
                            </h5>
                        </div>
                        <div className="col-1">
                            <span className="mt-5"><i className="fas fa-chevron-up font-272E35 font-16" onClick={handleToggle}></i></span>
                        </div>
                        <p className="font-w-400 font-16 pl-4 pr-5 font-6E757C mt-4">{ReactHtmlParser(answer)}</p>
                    </div>
                </div> : <div className="row">
                    <div className="col-11">
                        <h5 className="" onClick={handleToggle}>{ReactHtmlParser(question)}</h5>
                    </div>
                    <div className="col-1">
                        <span><i className="fas fa-chevron-down font-272E35 font-16" onClick={handleToggle}></i></span></div>
                </div>
            }

        </div>
    );
}

export default QuestionAnswerCard;
