/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "../../../assets/css/dashboard-style.css";
import "../../../assets/css/hover.css";
import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import ScanIcon from "../../../assets/images/scan-icon.png";
import WalletScanPopup from "./components/walletScanPopup";
import { getWalletAddress, setWalletAddress } from "../../utils/token";
import { updateWalletAddress } from "../nft/services";
import { setUserWalletAddress, setIsWalletConnected } from "../home/redux/homeAction";
import useAlert from "../../hooks/useAlert";
import metamaskService from "../smartContracts/metamask";
import { checkERC20Validation } from "./services";
import BankDetails from "../bankDetails";
import Tabs from "../../../components/common/tabs";
import { TAB } from "../../constants/constants";
import DashboardBanner from "../dashboard/components/dashboardBanner";
import DashboardHeading from "../dashboard/components/dashboardHeading";
import DashboardSummary from "../dashboard/components/dashboardSummery";
import SmartContractModal from "../../../components/modal/smartContractModal";

const WalletAddress = () => {
  const dispatch = useDispatch();
  const { showAlert } = useAlert();
  const { walletAddress, isMetamaskInstalled } = useSelector((state) => state.home);

  const [isOpen, setIsOpen] = useState(false);
  const [isClickConnectWallet, setIsClickConnectWallet] = useState(false);
  const [walletDetail, setWalletDetail] = useState(false);
  const [page, setPage] = useState(TAB.BIOCHAR)
  const [isLoading, setIsLoading] = useState(false);
  const [walletConnectIsLoading, setWalletConnectIsLoading] = useState(false);

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const address = getWalletAddress();
    setWalletDetail({ wallet: address && address !== "false" ? address : walletAddress });
  }, []);

  const initialValues = {
    wallet: walletDetail?.wallet ? walletDetail.wallet : "",
  };

  const formSchema = Yup.object().shape({
    wallet: Yup.string()
      .trim()
      .required("Wallet address must not be empty"),
    confirmWallet: Yup.string()
      .trim()
      .required("Confirm wallet address must not be empty")
      .oneOf([Yup.ref('wallet')], 'Your wallet address do not match.')
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      walletUpdate(values);
    },
  });

  const handleConnectWithWallet = async () => {
    setIsClickConnectWallet(true);
    setWalletConnectIsLoading(false);
    if (!isMetamaskInstalled) {
      alert("Please Install Metamask to use this service");
      return;
    }
    const result = await metamaskService.connectWithWallet();
    if (result && !result.error) {
      formik.setFieldValue("wallet", result?.accountAddress);
      await walletUpdate(result.accountAddress);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      alert(result.message);
    }
    setWalletConnectIsLoading(false);
  };


  const walletUpdate = async (values) => {
    setIsLoading(true);
    const value = values ? values : formik.values.wallet;
    const res = await checkERC20Validation({ address: value });
    if (res.status === true) {
      const data = {
        walletAddress: value,
      };
      const result = await updateWalletAddress(data);
      if (result.status) {
        dispatch(setUserWalletAddress(value));
        dispatch(setIsWalletConnected(true));
        setWalletAddress(value);
        showAlert("SUCCESS", "Success", "Wallet Address Updated");
      }
    } else {
      formik.setErrors({ ...formik.errors, confirmWallet: res.message });
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className="energy-nft-items m-4 m-lg-5">
        <Tabs setPage={setPage} />
        <div className="tab-content" id="myTabContent">
          {
            page === TAB.BIOCHAR &&
            <div className="card border-0">
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="energy-nft-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                  <DashboardBanner />
                  <div className="service-content">
                    <DashboardHeading tabs="no-tab" tabSwitcher="no" />
                    <DashboardSummary />
                    <SmartContractModal />
                  </div>
                  <div className="tab-pane fade show active" id="energynft" role="tabpanel" aria-labelledby="energynft-tab">
                    <div className="wallet-form-background d-grid">
                      <div className="wallet-form py-4 px-4">
                        <div className="kyc-form-head my-4  row">
                          <div className="col">
                            {" "}
                            <h2 className="kyc-pi ">Confirm your wallet address</h2>
                          </div>
                        </div>
                        <form className="row g-3 mb-4">
                          <div className="col-md-12 my-3">
                            <label htmlFor="inputEmail4" className="form-label label-gr">
                              Wallet Addrress
                            </label>
                            <input
                              type="text"
                              className="form-control bg-gr"
                              id="inputEmail4"
                              placeholder="Wallet Addrress"
                              name="wallet"
                              {...formik.getFieldProps("wallet")}
                            />
                            {(formik.touched.wallet || formik.errors.wallet) && (
                              <p className="text-danger">
                                <small>{formik.errors.wallet}</small>
                              </p>
                            )}
                          </div>
                        </form>
                        <div className="col-md-12 my-3">
                          <label htmlFor="inputEmail4" className="form-label label-gr">
                            Confirm Wallet Addrress
                          </label>
                          <input
                            type="text"
                            className="form-control bg-gr"
                            id="inputEmail4"
                            placeholder="Confirm Wallet Addrress"
                            name="confirmWallet"
                            {...formik.getFieldProps("confirmWallet")}
                          />
                          {!isClickConnectWallet && (formik.touched.confirmWallet || formik.errors.confirmWallet) && (
                            <p className="text-danger">
                              <small>{formik.errors.confirmWallet}</small>
                            </p>
                          )}
                        </div>
                        <div className="col-md-12 my-3 d-grid">
                          {
                            isLoading ?
                              <button type="submit" className="btn mt-4 f-bold kyc-sb no-bdr" disabled>
                                <div className="spinner-border" role="status">
                                  <span className="visually-hidden">Loading...</span>
                                </div>
                              </button>
                              :
                              <button type="submit" onClick={formik.handleSubmit} className="btn btn-primary mt-4 f-bold kyc-sb no-bdr">
                                Update
                              </button>
                          }
                        </div>
                        <div className="col-md-12 my-3 d-grid">
                          {
                            walletConnectIsLoading ?
                              <button type="submit" className="btn wallet-connect mt-4 f-bold kyc-sb no-bdr" disabled>
                                <div className="spinner-border" role="status">
                                  <span className="visually-hidden">Loading...</span>
                                </div>
                              </button>
                              :
                              <button className=" btn btn-primary mt-4 f-bold kyc-sb-2 no-bdr" onClick={handleConnectWithWallet}>
                                Connect Wallet
                              </button>
                          }
                        </div>
                        <div className="col-md-12 my-1 d-grid">
                          <a
                            className="btn btn-primary mt-4 f-bold ad-sc no-bdr d-flex align-items-center justify-content-center popup-with-zoom-anim"
                            onClick={showModal}
                          >
                            <img src={ScanIcon} alt="" srcSet="" className="me-2" /> Scan
                            your address
                          </a>
                        </div>
                      </div>
                      <BankDetails />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>

      <Modal show={isOpen} onHide={hideModal}>
        <Modal.Body>
          <WalletScanPopup hide={hideModal} data={formik.values} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default WalletAddress