/* eslint-disable no-unused-vars */
/* eslint-disable multiline-comment-style */
import React from "react";
import { Bar } from "react-chartjs-2";
import { getCashLimit, getCryptoLimit } from "../../app/utils/token";

const MonthlyInvestment = ({ data }) => {
  const cashDecimal = getCashLimit();
  const cryptoDecimal = getCryptoLimit();
  const SI_SYMBOL = ["", "k", "M", "B", "T", "P", "E"];
  function abbreviateNumber(number) {
    // what tier? (determines SI symbol)
    const tier = (Math.log10(Math.abs(number)) / 3) | 0;

    // if zero, we don't need a suffix
    if (tier === 0) return number;

    // get suffix and determine scale
    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);
    // scale the number
    const scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
  }

  const graphGrid = (data, name) => {
    return {
      labels: Object.entries(data).map((item) => item[0]),
      datasets: [
        {
          label: [Object.entries(data).map((item) => item[0])],
          data: Object.entries(data).map((item) => item[1]),
          backgroundColor: "#1890FF",
          borderRadius: 10,
          barThickness: 50,
        },
      ],
    };
  };
  const options = {
    // maintainAspectRatio: false,
    // scales: {
    //   y: {
    //     ticks: {
    //       callback: function (value, index, values) {
    //         return abbreviateNumber(value);
    //       },
    //     },
    //   },
    //   x: {
    //     display: true,
    //   },
    // },

    plugins: {
      legend: {
        display: false,
      },
      label: {
        display: false,
      },
      tooltip: {
        bodyAlign: "right",
        callbacks: {
          title: function (item, everything) {
            return;
          },
          label: function (item) {
            const name = item.label;
            let number = item.raw;
            number = number.toLocaleString("en-US", {
              minimumFractionDigits: 5,
            });
            const label = `${name} :  ${number}`;
            return label;
          },
        },
      },
    },

    responsive: true,
  };
  return (
    <>
      {data && (
        <Bar
          data={graphGrid(data, "Monthly Investment")}
          options={options}
          height={50}
        />
      )}
    </>
  );
};
export default MonthlyInvestment;
