const api_end_point = {
  SERVER_IMAGE_FOLDER_URL: process.env.REACT_APP_SERVER_IMAGE_FOLDER_URL,
  BASE_URL: process.env.REACT_APP_BASE_URL,
  NATURE_BASE_URL: process.env.REACT_APP_API_NATURE_BASE_URL,
  WALLET_BASE_URL: process.env.REACT_APP_WALLET_BASE_URL,
  SERVER_NATURE_IMAGE_FOLDER_URL: process.env.REACT_APP_SERVER_NATURE_IMAGE_FOLDER_URL,

  HOME_PAGE_DETAIL: "/api/v1/homepage/",
  DASHBOARD_URL: "/api/v1/get/dashboard_data",
  USER_DASHBOARD: "/api/v1/users/order/history",
  SMART_CONTRACT: "/api/v1/project/smartcontract/",
  LOGIN_USER_DETAILS: "/api/v1/get/user/details",
  SUMSUB_ACCESS_TOKEN: "/api/v1/auth/sumsub_access_token",
  KYC_STATUS: "/api/v1/kyc/status",
  ASSET_MASTER_DATA: "/api/v1/get/asset_master_data",
  // UPDATE_WALLET_ADDRESS: "/api/v1/update/wallet_address",
  UPDATE_WALLET_ADDRESS: "/api/v1/update/multi/wallet_address",
  LOGOUT: "/api/v1/auth/logout",
  ERC_20_VALIDATION: "/api/v1/erc20/token/validation",
  UPDATE_BANK_DETAILS: "/api/v1/update/bank/detail",
  CREATE_ASSET_REQUEST: "/api/v1/create/asset_request",
  GET_TOKEN_PRICE: "/api/v1/get/token/price",
  CRYPTO_CONFIRM_PAYMENT: "/api/v1/create/confirmpayment",
  REJECT_ASSET_REQUEST: "/api/v1/get/reject_asset_request",
  FOOTER: "/api/v1/footer",
  INVESTMENT_DETAIL: "/api/v1/get/investment/history",
  ABOUT: "/api/v1/homepage/about",
};

export default api_end_point;