/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { getNatureAccessToken, getWalletAddress, setWalletAddress, getQueryParams, getNatureUserId, loginRedirection } from "../utils/token";
import { setIsMetamaskInstalled, setIsWalletConnected, setUserLoginStatus, setUserWalletAddress } from "../pages/home/redux/homeAction";
import metamaskService from "../pages/smartContracts/metamask";
import SubsubKYC from "../pages/kyc/sumsubKyc";
import { dashboardAPI, getMasterData } from "../pages/nft/services";
import { setDashboardData, setMasterData, setSelectedCrypto } from "../pages/nft/redux/nftAction";
import { checkUserKYCStatus } from "../pages/kyc/redux/kycAction";
import { getUserKYCStatus } from "../pages/kyc/services";
import { checkLogin } from "./checkLogin";

const PrivateRoute = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isMetamaskInstalled } = useSelector((state) => state.home);
  const { dashboard, masterData, selectedCrypto } = useSelector((state) => state.nft);
  const { startKYC, isUserKYCDone } = useSelector((state) => state.kyc);

  const [isAuthenticate, setIsAuthenticate] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const accessToken = getNatureAccessToken();
        const userId = getNatureUserId();
        const routeData = getQueryParams();

        if (accessToken) {
          const result = await checkLogin({ user_id: parseInt(userId), access_token: accessToken });
          if (result) {
            !isUserKYCDone && dispatch(checkUserKYCStatus(getUserKYCStatus));
            dispatch(setUserLoginStatus(true));
            setIsAuthenticate(true);
            setIsLoading(false);
            let isAdmin = localStorage.getItem("isAdmin");
            isAdmin = JSON.parse(isAdmin);
            if (isAdmin) {
              window.location.replace(`${process.env.REACT_APP_ADMIN_NATURE_URL}?user_id=${routeData}&access_token=${accessToken}`);
            }
          } else {
            loginRedirection();
          }
        } else if (routeData?.access_token) {
          const result = await checkLogin(routeData);
          if (result) {
            dispatch(setUserLoginStatus(true));
            setIsAuthenticate(true);
            setIsLoading(false);
            let isAdmin = localStorage.getItem("isAdmin");
            isAdmin = JSON.parse(isAdmin);
            if (isAdmin) {
              window.location.replace(`${process.env.REACT_APP_ADMIN_NATURE_URL}?user_id=${routeData?.user_id}&access_token=${routeData?.access_token}`);
            }
          } else {
            loginRedirection();
          }
        } else {
          setIsAuthenticate(false);
          dispatch(setUserLoginStatus(false));
          setIsLoading(false);
          loginRedirection();
        }
      } catch (error) {
        console.log("privateRoute : ", error);
      }
    };

    const fetchMetamaskInstallation = async () => {
      try {
        const result = await metamaskService.checkMetamaskInstallation();
        if (result) {
          dispatch(setIsMetamaskInstalled(result));
          await fetchUserAddress();
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchUserAddress = async () => {
      const walletAddress = getWalletAddress();
      if (walletAddress !== "") {
        setWalletAddress(walletAddress);
        dispatch(setUserWalletAddress(walletAddress));
        const result = await metamaskService.getUserAddress();
        if (result && !result.error && result?.account === walletAddress) {
          dispatch(setIsWalletConnected(true));
        } else {
          dispatch(setIsWalletConnected(false));
        }
      }

      setIsLoading(false);

      // if (walletAddress === "") {
      //   const result = await metamaskService.getUserAddress();
      //   if (result && !result.error) {
      //     setWalletAddress(result.account);
      //     dispatch(setUserWalletAddress(result.account));
      //     dispatch(setIsWalletConnected(true));
      //     const data = {
      //       walletAddress: result.account,
      //     };
      //     await updateWalletAddress(data);
      //   } else {
      //     dispatch(setUserWalletAddress(null));
      //     dispatch(setIsWalletConnected(false));
      //   }
      //   setIsLoading(false);
      // }
    };

    const fetchDashboard = async () => {
      setIsLoading(true);
      const data = {
        token_id: selectedCrypto,
      };
      const res = await dashboardAPI(data);
      if (res.status) {
        const value = {
          dashboard: res.dashboard_data,
          offSet: res.utc_offset,
        };
        dispatch(setDashboardData(value));
        setIsLoading(false);
      }
    };

    const fetchMasterData = async () => {
      setIsLoading(true);
      const res = await getMasterData();
      if (res?.status === true) {
        dispatch(setSelectedCrypto(res?.crypto_tokens[0]?.id));
        dispatch(setMasterData(res));
        setIsLoading(false);
      }
    };
    const LocalStorageWalletAddress = getWalletAddress();

    fetchData();
    setTimeout(() => {
      !isMetamaskInstalled && fetchMetamaskInstallation();
      (isMetamaskInstalled || LocalStorageWalletAddress === "") && fetchUserAddress();
      (dashboard === null) && fetchDashboard();
      (masterData === null) && fetchMasterData();
    }, 3000);
  }, [location]);

  if (isLoading) {
    return (
      <div className="Loader2">
        <Spinner animation="border" />
      </div>
    );
  }

  if (startKYC) return <SubsubKYC />;

  return isAuthenticate ? <Outlet /> : <></>;
};

export default PrivateRoute;
