/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
// import { useSelector } from "react-redux";
import DashboardBanner from '../dashboard/components/dashboardBanner';
import { getAboutDetail } from './service';

const About = () => {
  // const { selectedCrypto, masterData } = useSelector((state) => state.nft);

  // const token_description = masterData?.crypto_tokens?.filter(ele => ele?.id === selectedCrypto)[0]?.token_description;
  // const [tokenDescription, setTokenDescription] = useState(token_description ? token_description : "");
  const [tokenDescription, setTokenDescription] = useState("");

  // useEffect(() => {
  //   const token_description = masterData?.crypto_tokens?.filter(ele => ele?.id === selectedCrypto)[0]?.token_description;
  //   setTokenDescription(token_description ? token_description : "");
  // }, [selectedCrypto])

  useEffect(() => {
    getPageDetail();
  }, []);

  const getPageDetail = async () => {
    const res = await getAboutDetail();
    if (res?.banner_ids?.length) {
      setTokenDescription(res.banner_ids[0].banner_text);
    }
  }

  return (
    <div className="card border-0">
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="energy-nft-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
          <DashboardBanner />
          <div className="service-content text-black">
            <div dangerouslySetInnerHTML={{ __html: tokenDescription }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default About