/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import userProfile from "../../../assets/images/user-profile.png";
import { setSuccessMessage, setErrorMessage } from "../../../redux/commonRedux/messageRedux/messageAction";
import { handleLogout, getUserName } from "../../utils/token";

const Profile = () => {
  const dispatch = useDispatch();
  const { message, messageType } = useSelector((state) => state.message);

  const name = getUserName();

  const handleRedirect = () => {
    window.location.href = `${process.env.REACT_APP_WALLET_URL}`;
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(setSuccessMessage());
      dispatch(setErrorMessage());
    }, 4000);
  }, [message]);

  return (
    <>
      <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
        {message && (
          <div className="notification" style={{ backgroundColor: messageType === "SUCCESS" ? "green" : "red", }}>
            {message}
          </div>
        )}
        <ul className="navbar-nav font-16 font-w-500 ">
          <li className="nav-item">
            <Dropdown>
              <Dropdown.Toggle variant="warning">
                <img src={userProfile} alt="" />{" "}
                <small className=" font-12 font-w-400">{name}</small>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={handleRedirect}>
                  Dashboard
                </Dropdown.Item>
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Profile;
