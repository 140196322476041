/* eslint-disable multiline-comment-style */
import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { useSelector } from "react-redux";
import { getCashLimit, getCryptoLimit } from "../../../utils/token";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const NFTGraph = () => {
  const { dashboard } = useSelector((state) => state.nft);

  const data = dashboard;
  const cashDecimal = getCashLimit();
  const cryptoDecimal = getCryptoLimit();
  const SI_SYMBOL = ["", "k", "M", "B", "T", "P", "E"];
  function abbreviateNumber(number) {
    // what tier? (determines SI symbol)
    const tier = (Math.log10(Math.abs(number)) / 3) | 0;

    // if zero, we don't need a suffix
    if (tier === 0) return number;

    // get suffix and determine scale
    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);
    // scale the number
    const scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
  }
  const graphGrid = (coinIssued, name) => {
    return {
      labels: [name],
      datasets: [
        {
          label: [name],
          data: [coinIssued],
          backgroundColor:
            name === "Total Circulating Supply" ? "#00A14B" : "#C5997D",
          borderRadius: 10,
          barThickness: 100,
        },
      ],
    };
  };
  const options = {
    scales: {
      y: {
        ticks: {
          callback: function (value, index, values) {
            return abbreviateNumber(value);
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      label: {
        display: false,
      },
      tooltip: {
        bodyAlign: "right",
        callbacks: {
          title: function (item, everything) {
            return;
          },
          label: function (item) {
            const name = item.label;
            let number = item.raw;
            number = number.toLocaleString("en-US", {
              minimumFractionDigits: 5,
            });
            const label = `${name} :  ${number}`;
            return label;
          },
        },
      },
    },

    responsive: true,
  };
  return (
    <>
      {data !== undefined && data !== null && (
        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
          <div className="db-graph">
            <div className="row">
              <div className="col-lg-6 mb-3 mb-lg-0">
                <div className="me-0 me-md-2 db-graph-inner">
                  <h4 className="mt-4 ms-4 font-656565">Sunset Token</h4>
                  <p className="text-center mt-4 mb-0 graph-text">
                    Token{" "}
                    {data.snt_total_circulating_supply.toLocaleString("en-US", {
                      minimumFractionDigits: cryptoDecimal,
                      maximumFractionDigits: cryptoDecimal,
                    })}
                  </p>
                  <div className="text-center w-100 p-4">
                    <Bar data={graphGrid(data.snt_total_circulating_supply, "Total Circulating Supply")} options={options} />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-3 mt-lg-0 ">
                <div className="me-0 me-md-2 db-graph-inner">
                  <h4 className="mt-4 ms-4 font-656565">Sunset USD</h4>
                  <p className="text-center mt-4 mb-0 graph-text">
                    USD{" "}
                    {data.snt_market_cap.toLocaleString("en-US", {
                      minimumFractionDigits: cashDecimal,
                      maximumFractionDigits: cashDecimal,
                    })}
                  </p>
                  <div className="text-center w-100 p-4">
                    <Bar data={graphGrid(data.snt_market_cap, "Total Market Cap")} options={options} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NFTGraph;
