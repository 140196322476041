import { messageConstant } from "./nftAction"

const initialState = {
  masterData: null,
  selectedCrypto: 1,
  dashboard: null,
  userDashboard: null,
  offSet: null,
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case messageConstant.SET_SELECTED_CRYPTO:
      return {
        ...state,
        selectedCrypto: action.payload.selectedCrypto,
      };
    case messageConstant.SET_DASHBOARD_DATA:
      return {
        ...state,
        dashboard: action.payload.dashboard,
        offSet: action.payload.offSet,
      };
    case messageConstant.SET_WALLET_DASHBOARD_DATA:
      return {
        ...state,
        userDashboard: action.payload.userDashboard,
      };
    case messageConstant.SET_MASTER_DATA:
      return {
        ...state,
        masterData: action.payload.masterData,
      };
    default: return state;
  }
};

export default messageReducer;