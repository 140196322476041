import axios from "axios";
import api_end_point from "../../../constants/api_end_point";

const getPageTypeData = async (pageType) => {
  const prevPage = localStorage.getItem("pagetype");
  let url = `${api_end_point.HOME_PAGE_DETAIL}${pageType}`;
  if (prevPage === "biochar" && pageType === "investors") {
    url = "/api/v1/get/investor/list/energy";
  }

  let baseUrl = api_end_point.NATURE_BASE_URL;
  if (pageType === "biochar") {
    baseUrl = api_end_point.BASE_URL;
  }

  const response = await axios({
    method: "Get",
    url: `${baseUrl}${url}`,
  });

  return response?.data;
};

const getLoginUserDetail = async (values) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.NATURE_BASE_URL}${api_end_point.LOGIN_USER_DETAILS}`,
    data: values
  });
  return await response?.data;
};

const logout = async (data) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.NATURE_BASE_URL}${api_end_point.LOGOUT}`,
    data: data
  });

  return response?.data;
}

const getFooterData = async (type) => {
  let url;
  if (type !== undefined) {
    url = `${api_end_point.FOOTER}/${type}`;
    if (type === "biochar") {
      type = "biochar";
    } else {
      type = "nature";
    }
  } else {
    url = `${api_end_point.FOOTER}`;
  }

  let baseUrl = api_end_point.NATURE_BASE_URL;
  if (type === "biochar") {
    baseUrl = api_end_point.BASE_URL;
  }

  const response = await axios({
    method: "Get",
    url: `${baseUrl}${url}`,
  });

  return response?.data;
};

export {
  getPageTypeData,
  getLoginUserDetail,
  logout,
  getFooterData,
}