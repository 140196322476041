/* eslint-disable camelcase */
import "../../../assets/css/footer.css";
import React from "react";
import specular from "../../../assets/images/faq-book.png";
import FrequentlyAskedQuestions from "./frequently-asked-questions";
import FooterBottom from "./footer-bottom";

const Footer = ({ pageName }) => {
  const get_url = window.location.pathname;
  const split = get_url.split("/");
  const currPage = split[1];

  console.log(currPage !== "nature-project" && currPage !== "biochar-project" && currPage !== "about" && currPage !== "corporate" && !!currPage)

  return (
    <>
      <div className="footer-main text-center">
        {currPage !== "nature-project" && currPage !== "biochar-project" && currPage !== "about" && currPage !== "corporate" && !!currPage ? (
          <>
            <img src={specular} className="mt-5" alt="" />
            <br />
            <div className="font-34A853 font-30">FAQ’s</div>
            <div className="mt-5 mb-5">
              <FrequentlyAskedQuestions pageName={pageName} />
            </div>
          </>
        ) : null}
        <div>
          <FooterBottom pageName={pageName} />
        </div>
      </div>
    </>
  );
};

export default React.memo(Footer);
