/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import api_end_point from '../../../constants/api_end_point';

const DashboardDocument = () => {
  const { selectedCrypto, masterData } = useSelector((state) => state.nft);

  const tokenDetail = masterData?.crypto_tokens?.filter(ele => ele?.id === selectedCrypto)[0];
  const [financialDocument, setFinancialDocument] = useState(tokenDetail?.financial_documents ? tokenDetail?.financial_documents : []);
  const [businessDocument, setBusinessDocument] = useState(tokenDetail?.business_documents ? tokenDetail?.business_documents : []);

  useEffect(() => {
    const tokenDetail = masterData?.crypto_tokens?.filter(ele => ele?.id === selectedCrypto)[0];
    setFinancialDocument(tokenDetail?.financial_documents ? tokenDetail?.financial_documents : []);
    setBusinessDocument(tokenDetail?.business_documents ? tokenDetail?.business_documents : []);
  }, [selectedCrypto])

  return (
    <div className="listing-style mb-4 mb-lg-5">
      <ul>
        <li className='d-flex'>Financial Document:
          {
            financialDocument?.length ?
              financialDocument.map((ele, index) => (
                <a className="document-btn" href={`${api_end_point.SERVER_IMAGE_FOLDER_URL}${ele?.document}`} target='_blank' key={index}>
                  <i className="fa fa-file" aria-hidden="true" />
                </a>
              ))
              :
              <></>
          }
        </li>
        <li className='d-flex'>Business Document :
          {
            businessDocument?.length ?
              businessDocument.map((ele, index) => (
                <a className="document-btn" href={`${api_end_point.SERVER_IMAGE_FOLDER_URL}${ele?.document}`} target='_blank' key={index}>
                  <i className="fa fa-file" aria-hidden="true" />
                </a>
              ))
              :
              <></>
          }
        </li>
      </ul>
    </div>
  )
}

export default DashboardDocument