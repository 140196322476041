/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import "../../../assets/css/frequently-asked-questions.css";
import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getPageTypeData } from "../../../app/pages/home/services/home";
import QuestionAnswerCard from "../../modal/question-answer-card"

const FrequentlyAskedQuestions = ({ pageName }) => {
  const { headerList } = useSelector((state) => state.home);
  const [questions, setQuestions] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const { pageType } = useParams();

  const [activeTab, setActiveTab] = useState("");
  const fetchPageData = async (
    link = "/api/v1/homepage/corporate",
    pageType = "corporate"
  ) => {
    const result = await getPageTypeData(pageType, link);
    if (result && result.message === "Successful") {
      setFaqs(result.faqs);
    } else {
      console.log(result);
    }
  };

  useEffect(() => {
    headerList &&
      headerList.length > 0 &&
      headerList.map((header, index) => {
        if (!pageType) {
          fetchPageData();
        } else if (header.page_type_name === pageType) {
          fetchPageData(`/${header.cms_url}`, pageType);
        }
      });
  }, [pageType, headerList]);

  useEffect(() => {
    const tempTabs = {};

    faqs.map((faq, index) => {
      if (index === 0) {
        setActiveTab(faq.category);
      }
      if (tempTabs[faq.category] === undefined) {
        tempTabs[faq.category] = [];
      }

      tempTabs[faq.category].push({
        question: faq.question,
        answer: faq.answer,
      });
      setTabs(tempTabs);
    });
  }, [faqs]);

  useEffect(() => {
    const questions = tabs[activeTab];
    questions && setQuestions(questions);
  }, [activeTab]);

  return (
    <div className="frequently-asked-question-main">
      <h1 className="font-36 font-w-600 mb-3 mb-sm-5 mb-md-5 mb-lg-5">
        Frequently asked questions
      </h1>
      <br />
      <div className="row">
        <div className="col-0 col-md-0 col-lg-1 col-xl-2 col-xxl-3"></div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8 col-xxl-6">
          <div className="mt-3 mt-sm-5 mt-md-5 mt-lg-5 p-2 px-sm-4 px-lg-0">
            <ul className="  display-block nav nav-pills nav-fill m-auto">
              {Object.keys(tabs).map((tab, index) => {
                return (
                  <li className="nav-item mx-0 mx-lg-2" key={index}>
                    <NavLink
                      className={
                        tab === activeTab
                          ? "nav-link font-w-600 active-tab"
                          : "nav-link font-w-600"
                      }
                      to=""
                      onClick={() => setActiveTab(tab)}
                    >
                      {tab}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="col-0 col-md-0 col-lg-1 col-xl-2 col-xxl-3"></div>
      </div>

      <div className="row mt-5">
        <div className="col-0 col-sm-0 col-md-0 col-lg-1 col-xl-2 col-xxl-3"></div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8 col-xxl-6">
          <div className="mt-5 mt-sm-2 px-sm-4 p-4 px-lg-0">
            {questions &&
              questions.map((question, index) => (
                <QuestionAnswerCard
                  key={index}
                  question={question.question}
                  answer={question.answer}
                />
              ))}
          </div>
        </div>
        <div className="col-0 col-sm-0 col-md-0 col-lg-1 col-xl-2 col-xxl-3"></div>
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestions;
