/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { setIsMetamaskInstalled, setIsWalletConnected, setUserLoginStatus, setUserWalletAddress } from "../pages/home/redux/homeAction";
import { getAccessToken } from "../utils/token";
import metamaskService from "../pages/smartContracts/metamask";
import { getMasterData } from "../pages/nft/services";
import { setMasterData } from "../pages/nft/redux/nftAction";

const PublicRoute = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { isMetamaskInstalled } = useSelector((state) => state.home);
  const { masterData } = useSelector((state) => state.nft);

  useEffect(() => {
    const fetchData = () => {
      try {
        const accessToken = getAccessToken();
        if (accessToken) {
          dispatch(setUserLoginStatus(true));
        } else {
          dispatch(setUserLoginStatus(false));
        }
        setIsLoading(false);
      } catch (error) {
        console.log("privateRoute : ", error);
      }
    };
    const fetchMetamaskInstallation = async () => {
      try {
        const result = await metamaskService.checkMetamaskInstallation();
        if (result) {
          dispatch(setIsMetamaskInstalled(result));
          fetchUserAddress();
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchUserAddress = async () => {
      const result = await metamaskService.getUserAddress();
      if (result && !result.error) {
        dispatch(setUserWalletAddress(result.account));
        dispatch(setIsWalletConnected(true));
      } else {
        dispatch(setUserWalletAddress(null));
        dispatch(setIsWalletConnected(false));
      }
      setIsLoading(false);
    };

    const fetchMasterData = async () => {
      setIsLoading(true);
      const res = await getMasterData();
      if (res.status === true) {
        dispatch(setMasterData(res));
        setIsLoading(false);
      }
    };

    fetchData();
    !isMetamaskInstalled && fetchMetamaskInstallation();
    isMetamaskInstalled && fetchUserAddress();
    (masterData === null) && fetchMasterData();
  }, [location]);

  if (isLoading) {
    return (
      <div className="Loader2">
        <Spinner animation="border" />
      </div>
    );
  }
  return <Outlet />;
};

export default PublicRoute;
