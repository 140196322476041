/* eslint-disable no-negated-condition */
import axios from "axios";
import api_end_point from "../../../constants/api_end_point";
import { getNatureAccessToken, getNatureUserId } from "../../../utils/token";

const dashboardAPI = async (val) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.DASHBOARD_URL}`,
    data: val
  });
  return response?.data;
};

const userDashboardAPI = async (val) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.USER_DASHBOARD}`,
    data: val
  });
  return response?.data;
};

const getSmartContract = async (values) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.SMART_CONTRACT}${values}`,
  });
  return response?.data;
};

const getMasterData = async () => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.ASSET_MASTER_DATA}`,
  });
  return response?.data;
};

const updateWalletAddress = async (values) => {
  const accessToken = getNatureAccessToken();
  const userId = getNatureUserId();
  const data = {
    user_id: parseInt(userId),
    access_token: accessToken,
    wallet_address: values.walletAddress,
  };
  const response = await axios({
    method: "Post",
    url: `${api_end_point.NATURE_BASE_URL}${api_end_point.UPDATE_WALLET_ADDRESS}`,
    data: data
  });
  return response?.data;
};

const createAssetRequest = async (values) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.CREATE_ASSET_REQUEST}`,
    data: values
  });
  return response?.data;
};

const getPriceToken = async (values) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.GET_TOKEN_PRICE}`,
    data: values
  });
  return response?.data;
};

const cryptoConfirmPayment = async (values) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.CRYPTO_CONFIRM_PAYMENT}`,
    data: values
  });
  return response?.data;
};

const rejectAssetRequest = async (values) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.REJECT_ASSET_REQUEST}`,
    data: values
  });
  return response?.data;
};

const getInvestmentDetail = async () => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.INVESTMENT_DETAIL}`,
  });
  return response?.data;
};

export {
  dashboardAPI,
  userDashboardAPI,
  getSmartContract,
  getMasterData,
  updateWalletAddress,
  createAssetRequest,
  getPriceToken,
  cryptoConfirmPayment,
  rejectAssetRequest,
  getInvestmentDetail,
}