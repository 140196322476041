/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-template */
/* eslint-disable camelcase */
import "../../../assets/css/header.css";
import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import sunsetCapitalLogo from "../../../assets/images/sunset_capital_logo.png";
import userProfile from "../../../assets/images/user-profile.png";
import WalletConnect from "../../../assets/images/WalletConnect.png";
import { getPageTypeData } from "../../../app/pages/home/services/home";
import { handleLogout, getUserName } from "../../../app/utils/token";
import MobileSideBar from "./mobile-side-bar";
import { setHeaderListData } from "../../../app/pages/home/redux/homeAction";
import Profile from "../../../app/pages/profile";
import { clearLocalStorage } from "../../../app/utils/token";

const Header = ({ page_type }) => {
  const dispatch = useDispatch();

  const { isLoggedIn, headerList } = useSelector((state) => state.home);

  const [name, setName] = useState();
  const [isFetching, setIsFetching] = useState(true);
  const [showMenuDropDown, setShowMenuDropDown] = useState(false);
  const [logo, setLogo] = useState();

  let shortName;
  if (name) {
    shortName = name.substring(0, 8) + "...";
  }

  const fetchPageData = async () => {
    const result = await getPageTypeData("biochar");
    if (result) {
      setTimeout(() => {
        dispatch(setHeaderListData(result.header_list));
        setLogo(result.header_logo);
        setIsFetching(false);
      });
    } else {
      console.log(result);
    }
  };

  const get_url = window.location.pathname;
  const split = get_url.split("/");
  let pageName;
  const isInArray = headerList?.filter((ele) => ele.page_type_name === split[1]);

  if (get_url === "/") {
    pageName = "home";
    // page_type = "public";
  } else if (isInArray?.length) {
    page_type = "public";
  } else {
    pageName = split[1];
    page_type = "private";
  }

  const handleRedirect = () => {
    window.location.href = `${process.env.REACT_APP_WALLET_URL}`;
  };

  const openNav = () => {
    document.getElementById("mySidenav").style.width = "250px";
  };

  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
  };

  useEffect(() => {
    fetchPageData();
    setTimeout(() => {
      const name = getUserName();
      setName(name);
    }, 1000);
  }, []);

  const handleLogin = () => {
    clearLocalStorage();
    window.location.href = `${process.env.REACT_APP_LOGIN_URL}?redirect_url=${window.location.href}dashboard`;
  };

  return (
    <div className={`header-main ${page_type === "public" ? "p-0 p-lg-4" : ""}`}>
      <nav className={`navbar navbar-expand-md ${page_type === "public" ? "rounded-corner shadow-partner" : ""} navbar-light bg-white`}>
        <div className="container-fluid" style={{ justifyContent: "center" }}>
          {page_type === "public" || page_type === "public-no-footer" ? (
            <button
              className="navbar-toggler ms-3 public"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setShowMenuDropDown(!showMenuDropDown)}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          ) : null}

          {page_type === "private" && (
            <button
              className="navbar-toggler ms-3 private"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={openNav}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          )}

          <NavLink className="logo m-auto" activeclassname="active" to="/">
            <img className="header-img" src={logo ? process.env.REACT_APP_BASE_URL + logo : sunsetCapitalLogo} alt="" />
          </NavLink>

          {isLoggedIn && page_type === "private" && <Profile />}

          {page_type === "public" || (page_type === "public-no-footer" && !isLoggedIn) ? (
            <>
              <div className="d-none d-md-block collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
                {isFetching ? (
                  <></>
                ) : (
                  <ul className="navbar-nav font-16 font-w-500">
                    {headerList &&
                      headerList.map((header, index) => {
                        return (
                          <li className="nav-item" key={index}>
                            <Link
                              className={`${"nav-link "}${header.page_type_name === pageName ? "active" : ""}`}
                              to={header.page_type_name === "home" ? "/" : `/${header.page_type_name}`}
                            >
                              {header.name}
                            </Link>
                          </li>
                        );
                      })}

                    {isLoggedIn ? (
                      <li className="nav-item">
                        <Dropdown>
                          <Dropdown.Toggle variant="warning">
                            <img src={userProfile} alt="" />{" "}
                            <small title={name} className=" font-12 font-w-400">
                              {shortName}
                            </small>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={handleRedirect}>
                              Dashboard
                            </Dropdown.Item>
                            <Dropdown.Item onClick={handleLogout}>
                              Logout
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    ) : (
                      <li className="nav-item">
                        <span className="nav-link p-0" onClick={() => handleLogin()}>
                          <button className="sunset-yellow-bg border-0 px-4 py-2 border-radidus-4">
                            Login
                          </button>
                        </span>
                      </li>
                    )}
                  </ul>
                )}
              </div>
            </>
          ) : null}
        </div>

        {page_type === "public" && (
          <div className="d-flex">
            <button className="d=flex justify-content-center align-items-center text-nowrap button-green p-3 connect-wallat-btn">
              <span>Connect Wallat</span>
              <span className="icon">
                <img src={WalletConnect} alt="wallat" />
              </span>
            </button>
          </div>
        )}
      </nav>
      <ul className={showMenuDropDown ? "d-block d-md-none dropdown-menu" : "dropdown-menu d-none"}>
        {headerList &&
          headerList.map((header, index) => {
            return (
              <li className="nav-item" key={index}>
                <NavLink
                  className="nav-link"
                  activeclassname="active"
                  to={header.page_type_name === "home" ? "/" : `/${header.page_type_name}`}
                  onClick={() => setShowMenuDropDown(false)}
                >
                  {header.name}
                </NavLink>
              </li>
            );
          })}
        {isLoggedIn ? (
          <>
            <li className="nav-item" onClick={handleRedirect}>
              <Link className="nav-link dropdown-item" to="#">
                Dashboard
              </Link>
            </li>
            <li className="nav-item" onClick={handleLogout}>
              <Link className="nav-link dropdown-item" to="#">
                Logout
              </Link>
            </li>
          </>
        ) : (
          <li className="nav-item">
            <span className="nav-link dropdown-item" activeclassname="active" onClick={() => handleLogin()}>
              Login
            </span>
          </li>
        )}
      </ul>
      {page_type === "private" && (
        <div id="mySidenav" className="sidenav">
          <a href="#" className="closebtn" onClick={closeNav}>
            &times;
          </a>
          <MobileSideBar />
        </div>
      )}
    </div>
  );
};

export default React.memo(Header);
