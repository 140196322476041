import React from 'react'

const PrivateFooter = () => {
  return (
    <div className="footer w-100 float-start">
      <div className="copyright text-center">
        <p className="mb-0">©2023 <a href="https://sunsetcapitdev.wpengine.com/"
          className="text-decoration-none">SunsetCapital</a>, All Rights Reserved.</p>
      </div>
    </div>
  )
}

export default PrivateFooter